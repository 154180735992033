<script setup>
import logo from "../assets/logo_vue.png";
import { ref, inject } from 'vue';
import Drawer from "./Drawer.vue";
import { translate } from './translations.js';

const { isHome } = defineProps({
    isHome: {
        type: Boolean,
        default: false,
    },
})

const currentLanguage = inject('currentLanguage');
const changeLanguage = inject('changeLanguage');
</script>
<template>        
    <div class="sticky top-0 bg-red-500 text-white p-4 rounded w-full text-center text-2xl font-bold z-50 ">{{ translate('temporarily_closed') }}</div>

    <header :class="['sticky-header', 'flex items-center', 'p-5', {'px-0 bg-white scrolled': !isHome}]">
    
    
        <a href="/" :class="['d-inline-block', {'home-logo': isHome}]">
    
            <div :class="{'page-header-logo': !isHome}"><img :src="logo" alt="Logo" class=""></div>
    
        </a>
    
    
    
        <nav class="ml-auto">
    
    
    
    
    
            <div :class="['flex', 'flex-row', 'items-center', {'nav-position': isHome}]">
    
    
    
    
    
    
    
                <div class="nav-item" v-if="!isHome">
                    <a target="_blank" href="https://wa.me/18493576458" class="text-blue-800 text-xs md:text-base font-medium uppercase make-appointment-header-link">{{translate('make_your_appointment')}}</a>
                </div>
    
    
    
    
    
    
    
                <div class="nav-item language-buttons-container md:block nav-item language-buttons-container">
    
    
    
    
    
    
    
                    <button :class="{ 'active': currentLanguage === 'esp' }" class="language-button h-6 w-8 sm:w-12 md:w-16 border text-xs border-slate-700 rounded-l focus:bg-customObscureBlue focus:text-white uppercase" data-lang="esp" @click="changeLanguage('esp')">
    
        
    
            
    
        
    
                          {{ translate('spanish_abbr') }}
    
        
    
            
    
        
    
                        </button>
    
    
    
    
    
    
    
                    <button :class="{ 'active': currentLanguage === 'en' }" class="language-button h-6 w-8 sm:w-12 md:w-16 border rounded-r text-xs border-slate-700 focus:bg-customObscureBlue focus:text-white uppercase" data-lang="en" @click="changeLanguage('en')">
    
        
    
            
    
        
    
                          {{ translate('english_abbr') }}
    
        
    
            
    
        
    
                        </button>
    
    
    
    
    
    
    
                </div>
    
    
    
    
    
    
    
                <div class="nav-item">
    
    
    
    
    
    
    
                    <Drawer />
    
    
    
    
    
    
    
                </div>
    
    
    
    
    
    
    
            </div>
    
    
    
    
    
    
    
        </nav>
    
    
    
    
    
    
    
    </header>
    
    
    
    <div class="right-image" v-if="isHome">
    
        <div class="flex flex-col md:flex-row items-center home-info-container">
    
            <div class="hidden md:block w-full md:w-1/2 z-10">
    
                <div class="p-10">
    
                    <h1 class="text-[20px] home-big-text custom-blue font-black mt-8 w-75 text-left">{{ translate('taking_care_of_kids') }}</h1>
    
                    <a target="_blank" href="https://wa.me/18493576458" class="text-white custom-bg-orange rounded-xl text-sm appointment-button inline-block appointment-button-home">{{translate('make_your_appointment')}}!</a>
    
                </div>
    
            </div>
    
            <div class="w-full md:w-1/2 relative"></div>
    
        </div>
    
    </div>
    
    <div  class="w-full h-full flex flex-col justify-center items-center px-6 py-10 md:hidden z-1" v-if="isHome">
    
        <h1 class="home-big-text font-bold custom-blue font-extrabold text-left">{{ translate('taking_care_of_kids') }}</h1>
    
        <a target="_blank" href="https://wa.me/18493576458" class="appointment-button text-white custom-bg-orange rounded-xl text-md uppercase">{{translate('make_your_appointment')}}!</a>
    
    </div>
    
</template>
